export enum ETypeLaboratoryParameter {
    CUSTOMER = 1,
    EMPLOYEE = 2,
    SUPPLIER = 3,
}

export enum EGroupLaboratoryParameter {
    BASIC = 1,
    ADDITIONAL = 2,
}

export enum EFieldLaboratoryParameter {
    CUSTOM = 1,
    EMAIL = 2,
    NAME = 3,
    LEGAL_NAME = 4,
    DOCUMENT = 5,
    GENDER = 6,
    BIRTHDATE = 7,
    MEMBER_SINCE = 8,
    RESPONSIBLE = 9,
    CRO = 10,
    CLINIC_NAME = 11,
    DOCUMENT_REGIME = 12,

    NUMBER = 12000,
}

export enum EParameterLaboratoryParameter {
    INPUT = 1,
    SELECT_CHECKBOX = 2,
    SELECT = 3,
    INPUT_DATE = 4,
}

export enum EStatusLaboratoryParameter {
    ACTIVE = 1,
    INACTIVE = 2,
}

export enum EVisibilityLaboratoryParameter {
    LAB = 1,
    BOTH = 2,
}
